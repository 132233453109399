import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import GofloaterSearchForm from "../components/search/searchalter"
import SafeSpace from "../img/safety-badge.jpg"
import SEOHeader from "../components/seo-header"
import { graphql, Link } from "gatsby"
import OfficeSpace from "./office-space.png"
import Img from "gatsby-image"
const CityPages = props => {
  const { city } = props.pageContext
  const { displaycity } = props.pageContext
  const { slug } = props.pageContext
  const [spaceFilter, setspaceFilter] = useState("All")
  const [spaceSize, setspaceSize] = useState([])
  const [CospaceSize, setCospaceSize] = useState([])
  const [MspaceSize, setMspaceSize] = useState([])
  const lists = props.data.office.edges
  const listscoworking = props.data.coworking.edges
  const listsmeeting = props.data.meeting.edges
  const coworkingBanner = props.data.officeimage.childImageSharp.fluid
  useEffect(() => {
    setspaceSize(lists.length)
    setCospaceSize(listscoworking.length)
    setMspaceSize(listsmeeting.length)
  })
  const locality = props.data.allLocalities.edges
  const MeetingLocalityImage = name => {
    const localitynew = listsmeeting.filter(
      list => list.node.spaceCity === name
    )

    if (localitynew.length > 0) {
      return localitynew[0].node.spaceImage
    }
    return ""
  }
  const CoworkingLocalityImage = name => {
    const localitynew = listscoworking.filter(
      list => list.node.spaceCity === name
    )

    if (localitynew.length > 0) {
      return localitynew[0].node.spaceImage
    }
    return ""
  }
  const LocalityCityImage = name => {
    const localitynew = lists.filter(list => list.node.spaceCity === name)
    if (localitynew.length > 0) {
      return localitynew[0].node.spaceImage
    }
    return ""
  }

  return (
    <div>
      <SEOHeader
        title={
          "Best coworking, meeting and office spaces in " +
          city +
          " | Gofloaters"
        }
        description={
          "Get access to affordable, verified and fully equipped coworking spaces, meeting spaces and office spaces in " +
          city +
          " on GoFloaters instantly."
        }
        socialURL={"https://assets.gofloaters.com/socialimage/" + slug + ".jpg"}
        pinterest="true"
      ></SEOHeader>
      <Layout>
        <div>
          {/*<HeaderBanner headerclass="office-space"></HeaderBanner>*/}
          <div className="coworkingSpaces">
            <div className="container">
              <div className="row">
                <div className="col-md-2 "></div>
                <div className="col-md-8 meetingSpacesSearch">
                  <h1>
                    Book Coworking, Meeting and Shared Office Spaces in{" "}
                    {displaycity}{" "}
                  </h1>
                  <div style={{ width: "90%" }}>
                    <p className="subHeading">
                      GoFloaters helps you find and book a coworking space near
                      you for meetings and work.
                    </p>
                  </div>
                  <div style={{ width: "90%" }}>
                    <GofloaterSearchForm
                      placeholder="where would you like to work or meet..."
                      spacetype="dailyofficeSpace"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <br></br>
          <div className="row">
            {CospaceSize !== 0 ? (
              <Link
                to={"/coworking-spaces/" + slug + "/all"}
                className="col-md-4"
              >
                <div className="localityBox">
                  <div
                    className="localityimageBox"
                    style={{
                      backgroundImage:
                        "url(" + CoworkingLocalityImage(city) + ")",
                    }}
                  ></div>
                  <h4 className="localityboxText alignerRight">
                    <span>
                      {CospaceSize} Coworking Spaces in<br></br>
                      {city}
                    </span>
                    <span style={{ paddingLeft: "10px" }}>
                      <i className="fa fa-arrow-circle-right"></i>
                    </span>
                  </h4>
                </div>
              </Link>
            ) : (
              ""
            )}
            {MspaceSize !== 0 ? (
              <Link
                to={"/meeting-spaces/" + slug + "/all"}
                className="col-md-4"
              >
                <div className="localityBox">
                  <div
                    className="localityimageBox"
                    style={{
                      backgroundImage:
                        "url(" + MeetingLocalityImage(city) + ")",
                    }}
                  ></div>
                  <h4 className="localityboxText alignerRight">
                    <span>
                      {MspaceSize} Meeting Spaces in<br></br>
                      {city}
                    </span>
                    <span style={{ paddingLeft: "10px" }}>
                      <i className="fa fa-arrow-circle-right"></i>
                    </span>
                  </h4>
                </div>
              </Link>
            ) : (
              ""
            )}
            {MspaceSize !== 0 ? (
              <Link to={"/office-spaces/" + slug + "/all"} className="col-md-4">
                <div className="localityBox">
                  <div
                    className="localityimageBox"
                    style={{
                      backgroundImage: "url(" + LocalityCityImage(city) + ")",
                    }}
                  ></div>
                  <h4 className="localityboxText alignerRight">
                    <span>
                      {spaceSize} Office Spaces in<br></br>
                      {city}
                    </span>
                    <span style={{ paddingLeft: "10px" }}>
                      <i className="fa fa-arrow-circle-right"></i>
                    </span>
                  </h4>
                </div>
              </Link>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="padding60">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2
                  style={{
                    fontSize: "26px",
                    fontWeight: "bold",
                    lineHeight: "1.3",
                  }}
                >
                  Amenities
                </h2>
              </div>
              <div className="col-md-4">
                <div className="container">
                  <div className="row iconCoworking aligner">
                    <div className="col-md-3 col-3">
                      <img
                        src="https://assets.gofloaters.com/icons/hi_speed_wifi.png"
                        alt="High Speed WiFi"
                        className="img-responsive"
                      ></img>
                    </div>
                    <div className="col-md-9 col-9">
                      <h3>High Speed WiFi</h3>
                      <p>
                        High-Speed WiFi to get you going the moment you enter.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="container">
                  <div className="row iconCoworking aligner">
                    <div className="col-md-3 col-3">
                      <img
                        src="https://assets.gofloaters.com/icons/power_backup.png"
                        alt="Power Backup"
                        className="img-responsive"
                      ></img>
                    </div>
                    <div className="col-md-9 col-9">
                      <h3>Power Backup</h3>
                      <p>
                        Power backup to ensure that you are able to work
                        uninterrupted.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="container">
                  <div className="row iconCoworking aligner">
                    <div className="col-md-3 col-3">
                      <img
                        src="https://assets.gofloaters.com/icons/ac.png"
                        alt="AC"
                        className="img-responsive"
                      ></img>
                    </div>
                    <div className="col-md-9 col-9">
                      <h3>AC</h3>
                      <p>
                        Climate controlled environment with cooling set
                        appropriately to make your work pleasurable
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="container">
                  <div className="row iconCoworking aligner">
                    <div className="col-md-3 col-3">
                      <img
                        src="https://assets.gofloaters.com/icons/free_parking.png"
                        alt="Parking"
                        className="img-responsive"
                      ></img>
                    </div>
                    <div className="col-md-9 col-9">
                      <h3>Parking</h3>
                      <p>Free parking inside and near the space.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="container">
                  <div className="row iconCoworking aligner">
                    <div className="col-md-3 col-3">
                      <img
                        src="https://assets.gofloaters.com/icons/coffee_tea.png"
                        alt="Coffee / Tea"
                        className="img-responsive"
                      ></img>
                    </div>
                    <div className="col-md-9 col-9">
                      <h3>Tea & Coffee</h3>
                      <p>
                        Recharge yourself with a coffee or your favorite
                        beverage when needed.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="container">
                  <div className="row iconCoworking aligner">
                    <div className="col-md-3 col-3">
                      <img
                        src="https://assets.gofloaters.com/icons/printer.png"
                        alt="printer"
                        className="img-responsive"
                      ></img>
                    </div>
                    <div className="col-md-9 col-9">
                      <h3>Printer</h3>
                      <p>
                        Printing and scanning facilities available on request.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                {" "}
                <br></br>
                <p style={{ fontSize: "12px" }}>
                  * Check the space details page for each space to explore the
                  amenities available at that space
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="padding60">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2
                  style={{
                    fontSize: "26px",
                    fontWeight: "bold",
                    lineHeight: "1.3",
                  }}
                >
                  The right solution for
                </h2>
              </div>
              <div className="col-md-4 CoworkingimageBox">
                <img
                  src="https://assets.gofloaters.com/coworking/remote-team.jpg"
                  alt="Remote Teams"
                ></img>
                <h4>Remote Teams</h4>
              </div>
              <div className="col-md-4 CoworkingimageBox">
                <img
                  src="https://assets.gofloaters.com/coworking/startups.jpg"
                  alt="Startups"
                ></img>
                <h4>Startups</h4>
              </div>
              <div className="col-md-4 CoworkingimageBox">
                <img
                  src="https://assets.gofloaters.com/coworking/freelancer.jpg"
                  alt="Freelancers"
                ></img>
                <h4>Freelancers</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row textJustify">
            <CoworkingContent city={city} />
          </div>
        </div>
        <div className="padding60 brandBackground">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2
                  style={{
                    fontSize: "26px",
                    fontWeight: "bold",
                    lineHeight: "1.3",
                    color: "#fff",
                  }}
                >
                  GoFloaters Advantage
                </h2>
              </div>
              <div className="container roundWhiteBackground">
                <div className="row">
                  <div className="col-md-4">
                    <div className="gofloaters-advantage alignerRight">
                      <img
                        src="https://assets.gofloaters.com/coworking/pay-you-go.svg"
                        alt="Pay as you use"
                      ></img>
                      <p>Pay as you use</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="gofloaters-advantage alignerRight">
                      <img
                        src="https://assets.gofloaters.com/coworking/largest-network.svg"
                        alt="Largest Network"
                      ></img>{" "}
                      <p>Largest Network</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="gofloaters-advantage alignerRight">
                      <img
                        src="https://assets.gofloaters.com/coworking/affordable.svg"
                        alt="Affordable"
                      ></img>{" "}
                      <p>Affordable</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="gofloaters-advantage alignerRight">
                      <img
                        src="https://assets.gofloaters.com/coworking/work-near-home.svg"
                        alt="Work near home"
                      ></img>{" "}
                      <p>Work near home</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="gofloaters-advantage alignerRight">
                      <img
                        src="https://assets.gofloaters.com/coworking/no-contract.svg"
                        alt="No contracts"
                      ></img>{" "}
                      <p>No contracts</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="gofloaters-advantage alignerRight">
                      <img
                        src="https://assets.gofloaters.com/coworking/partner-benefits.svg"
                        alt="Partner benefits"
                      ></img>{" "}
                      <p>Partner benefits</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="text-center" style={{ padding: "30px 0px" }}>
          <h3 style={{ color: "#000", fontWeight: "bold" }}>
            Covid-19 Safety Measures
          </h3>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6 text-center">
              <p>
                GoFloaters cares about your well being and wants to ensure that
                you feel safe while working out of our spaces. That’s why we’ve
                developed the Safety Badge program along with our partner
                spaces.
              </p>
            </div>
            <div className="col-md-3"></div>
            <div className="col-md-3"></div>
            <div className="col-md-6 text-center">
              <br></br>
              <p className="text-center">
                The program is a voluntary pledge that our spaces take to ensure
                that their space meets our requirements for health and safety
                standards.
              </p>
            </div>
            <div className="col-md-3"></div>
          </div>
          <div
            className="row"
            style={{
              border: "1px solid #d2dcf0",
              padding: "15px 20px",
              margin: "50px",
              borderRadius: "8px",
            }}
          >
            <div className="col-md-12 text-center">
              <h3>Safety Badge Requirements</h3>
              <p style={{ color: "#303030" }}>
                Required for Verified COVID Safety Badge
              </p>
              <br></br>
            </div>
            <div className="col-md-12 covid-response MobileOnly">
              <ul>
                <li>Mask Required</li>
                <li>Hand Sanitizer Provided</li>
                <li>Social Distancing between Workstations</li>
                <li>Cleaning and disinfection of Common Surfaces</li>
                <li>Safe Food Preparation and Pantry Services</li>
                <li>Visitor Records for Contact Tracing</li>
              </ul>
            </div>
            <div className="col-md-6 covid-response DesktopOnly">
              <ul>
                <li>Mask Required</li>
                <li>Hand Sanitizer Provided</li>
                <li>Social Distancing between Workstations</li>
              </ul>
            </div>
            <div className="col-md-6 covid-response DesktopOnly">
              <ul>
                <li>Cleaning and disinfection of Common Surfaces</li>
                <li>Safe Food Preparation and Pantry Services</li>
                <li>Visitor Records for Contact Tracing</li>
              </ul>
            </div>
          </div>
          <div
            className="row"
            style={{
              padding: "15px 20px",
              margin: "40px",
            }}
          >
            <div className="col-md-6">
              <br></br>
              <h3>Identifying Safe Spaces</h3>
              <p>
                GoFloaters Safe Space badge will appear next to spaces that have
                verified safety measures undertaken and have met our safety
                standards.
              </p>
            </div>
            <div className="col-md-6 text-center">
              <img
                src={SafeSpace}
                style={{ width: "200px" }}
                alt="GoFloaters Verified Safe"
              ></img>
            </div>
          </div>
          <div
            className="row"
            style={{
              padding: "15px 20px",
              margin: "40px",
            }}
          ></div>
        </div> */}
        {/* <div className="" id="unlockDeals">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2>Unlock exclusive deals when you sign up</h2>
                <p>
                  We offer ₹1.5 Crores in free benefits and discounts for
                  eligible startups* through our partner program
                </p>
              </div>
            </div>
            <div className="row MobileOnly">
              <div className="col-6">
                <div className="partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/todoist.svg"
                    className="lazyload height"
                    alt="todoist"
                    width="108"
                    height="30"
                  ></img>
                  <p>1 free year of Business plan</p>
                </div>
              </div>
              <div className="col-6">
                <div className="partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/miro.svg"
                    className="lazyload width"
                    alt="miro"
                    width="90"
                    height="32"
                  ></img>
                  <p>$1000 in credits</p>
                </div>
              </div>
              <div className="col-6">
                <div className="partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/aws.png"
                    className="lazyload height"
                    alt="aws"
                    width="50"
                    height="30"
                  ></img>
                  <p>$1000 credit for 2 years</p>
                </div>
              </div>
              <div className="col-6">
                <div className="partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/razorpay.png"
                    className="lazyload width"
                    alt="razorpay"
                    width="90"
                    height="19"
                  ></img>
                  <p>Zero transaction fee upto ₹4L</p>
                </div>
              </div>
              <div className="col-6">
                <div className="partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/hubspot.png"
                    className="lazyload height"
                    alt="hubspot"
                    width="103"
                    height="30"
                  ></img>
                  <p>90% off on yearly plans</p>
                </div>
              </div>
              <div className="col-6">
                <div className="partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/udemy.png"
                    className="lazyload height"
                    alt="udemy"
                    width="103"
                    height="30"
                  ></img>
                  <p>Upto 95% off on courses</p>
                </div>
              </div>
              <div className="col-6">
                <div className="partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/segment.svg"
                    className="lazyload height"
                    alt="segment"
                    width="108"
                    height="30"
                  ></img>
                  <p>
                    $50,000 in credits + discounts on 35+ products worth $1M
                  </p>
                </div>
              </div>
              <div className="col-6">
                <div className="partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/typeform.png"
                    className="lazyload height"
                    alt="typeform"
                    width="47"
                    height="30"
                  ></img>
                  <p>50% of monthly & yearly plans</p>
                </div>
              </div>
              <div className="col-6">
                <div className="partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/kaleyra.png"
                    className="lazyload height"
                    alt="kaleyra"
                    width="124"
                    height="30"
                  ></img>
                  <p>₹8000 credit for 1 year</p>
                </div>
              </div>
            </div>
            <div className="row gofloaterHomePartner DesktopOnly">
              <div className="col-md-1"></div>
              <div className="col-md-2">
                <div className="column1 partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/todoist.svg"
                    className="lazyload height"
                    alt="todoist"
                    width="108"
                    height="30"
                  ></img>
                  <p>1 free year of Business plan</p>
                </div>
              </div>
              <div className="col-md-2 columns2">
                <div className=" column2 partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/miro.svg"
                    className="lazyload width"
                    alt="miro"
                    width="90"
                    height="32"
                  ></img>
                  <p>$1000 in credits</p>
                </div>
                <div className="column2 partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/aws.png"
                    className="lazyload height"
                    alt="aws"
                    width="50"
                    height="30"
                  ></img>
                  <p>$1000 credit for 2 years</p>
                </div>
              </div>
              <div className="col-md-2">
                <div className="column3 partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/razorpay.png"
                    className="lazyload width"
                    alt="razorpay"
                    width="90"
                    height="19"
                  ></img>
                  <p>Zero transaction fee upto ₹4L</p>
                </div>
                <div className="column3 partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/hubspot.png"
                    className="lazyload height"
                    alt="hubspot"
                    width="103"
                    height="30"
                  ></img>
                  <p>90% off on yearly plans</p>
                </div>
                <div className="column3 partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/udemy.png"
                    className="lazyload height"
                    alt="udemy"
                    width="103"
                    height="30"
                  ></img>
                  <p>Upto 95% off on courses</p>
                </div>
              </div>
              <div className="col-md-2 columns2">
                <div className=" column4 partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/segment.svg"
                    className="lazyload height"
                    alt="segment"
                    width="108"
                    height="30"
                  ></img>
                  <p>
                    $50,000 in credits + discounts on 35+ products worth $1M
                  </p>
                </div>
                <div className="column4 partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/typeform.png"
                    className="lazyload height"
                    alt="typeform"
                    width="47"
                    height="30"
                  ></img>
                  <p>50% of monthly & yearly plans</p>
                </div>
              </div>
              <div className="col-md-2">
                <div className="column5 partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/kaleyra.png"
                    className="lazyload height"
                    alt="kaleyra"
                    width="124"
                    height="30"
                  ></img>
                  <p>₹8000 credit for 1 year</p>
                </div>
              </div>
              <div className="col-md-1"></div>
            </div>
            <div className="row">
              <div className="col-md-12 text-center">
                <p>&nbsp;</p>
                <Link
                  to="/our-community-perks/"
                  title="Our Community Perks"
                  style={{ color: "#303030", fontWeight: "bold" }}
                >
                  ...and many more
                </Link>
              </div>
            </div>
          </div>
        </div> */}
        <div className="padding-60">
          <div className="container">
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <div className="container">
                  <div className="row ">
                    <div className="col-md-12 nocolpadding">
                      <p>Trusted by 10,000+ customers including</p>
                    </div>
                    <br></br>
                    <div className="col-md-3 col-4 text-center img-hteams fivePixelPadding noLeftPadding">
                      <img
                        data-src="https://assets.gofloaters.com/teams/decalthaonlogo.svg"
                        alt="Decalthaon"
                        className="lazyload"
                        width="159"
                        height="40"
                      ></img>
                    </div>
                    <div className="col-md-3 col-4 text-center img-hteams fivePixelPadding">
                      <img
                        data-src="https://assets.gofloaters.com/teams/indusind.png"
                        alt="Indusind"
                        className="lazyload"
                        width="154"
                        height="18"
                      ></img>
                    </div>
                    <div className="col-md-3 col-4 text-center img-hteams fivePixelPadding">
                      <img
                        data-src="https://assets.gofloaters.com/teams/nippon.png"
                        alt="Nippon"
                        className="lazyload"
                        width="154"
                        height="44"
                      ></img>
                    </div>
                    <div className="col-md-3 col-4 text-center img-hteams fivePixelPadding">
                      <img
                        data-src="https://assets.gofloaters.com/teams/homelane.png"
                        alt="Homelane"
                        className="lazyload"
                        width="159"
                        height="38"
                      ></img>
                    </div>
                    <div className="col-md-3 col-4 text-center img-hteams fivePixelPadding">
                      <img
                        data-src="https://assets.gofloaters.com/clients/shriram.png"
                        alt="Shriram"
                        className="lazyload"
                        width="154"
                        height="46"
                      ></img>
                    </div>
                    <div className="col-md-3 col-4 text-center img-hteams fivePixelPadding">
                      <img
                        data-src="https://assets.gofloaters.com/clients/notion-press.png"
                        alt="Notion Press"
                        className="lazyload"
                        width="154"
                        height="34"
                      ></img>
                    </div>
                    <div className="col-md-3 col-4 text-center img-hteams fivePixelPadding">
                      <img
                        data-src="https://assets.gofloaters.com/teams/mind-mom.png"
                        alt="Fusion"
                        className="lazyload"
                        width="154"
                        height="42"
                      ></img>
                    </div>
                    <div className="col-md-3 col-4 text-center img-hteams fivePixelPadding">
                      <img
                        data-src="https://assets.gofloaters.com/teams/think-music.jpg"
                        alt="Think Music"
                        className="lazyload"
                        width="154"
                        height="64"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8"></div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default CityPages

function CoworkingContent(props) {
  switch (props.city) {
    case "Chandigarh":
      return (
        <div className="col-md-12">
          <h4 style={{ fontWeight: "bold" }}>
            Looking for the best coworking space in Chandigarh?
          </h4>

          <p>
            Nestled at the foothills of the Shivalik range, Chandigarh is not
            only celebrated for its meticulous urban planning but is also
            becoming a hub for innovation and entrepreneurship. With its blend
            of modernity and natural beauty, Chandigarh has attracted a
            community of forward-thinkers seeking collaborative work
            environments. The city's entrepreneurial spirit is thriving, and
            co-working spaces have emerged as a cornerstone of this ecosystem.
            From the bustling Sector 17 to the tranquil Sukhna Lake, coworking
            spaces like InnovateHub, ConnectCo, and WorkSphere provide
            professionals a platform to synergize their ideas, network, and grow
            in an environment that encapsulates Chandigarh's distinct charm.
            Find{" "}
            <a href="https://gofloaters.com/coworking-spaces/chandigarh/">
              Best Coworking Spaces in Chandigarh
            </a>{" "}
            in one click.
            <br />
            <br />
            And, if you're meeting your team or planning a presentation?
            GoFloaters has a solution.
            <br />
            <br />
            Chandigarh's meeting rooms, equipped with state-of-the-art
            technology, offer the ideal setting for discussions that propel
            businesses forward. Whether it's a corporate presentation at a hotel
            like The Lalit Chandigarh, a strategy session at Hyatt Regency, or a
            more intimate discussion at Le Monarque, the city provides an array
            of meeting spaces that cater to diverse needs. These venues not only
            facilitate efficient discussions but also offer a glimpse into
            Chandigarh's ethos of blending innovation with tradition.
            <br />
            <br />
            Check meeting spaces for rent in Chandigarh{" "}
            <a href="https://gofloaters.com/meeting-spaces/chandigarh/">here</a>
            !
            <br />
            <br />
            If you're a remote team and want to rent a shared office space in
            Chandigarh, we hear you!
            <br />
            <br />
            As businesses evolve, so do their workspace requirements. Shared
            office spaces have become a transformative solution, allowing
            enterprises to harness flexibility without compromising on
            functionality. With shared amenities, customizable layouts, and
            cost-effective plans, these spaces encapsulate Chandigarh's ethos of
            blending tradition with modernity, providing a conducive environment
            for businesses to flourish. Find office spaces for rent in
            Chandigarh{" "}
            <a href="https://gofloaters.com/office-spaces/chandigarh/">
              here
            </a>.{" "}
          </p>
          <br />
          <br />
        </div>
      )
    default:
      return ""
  }
}
export const query = graphql`
  query CityPages($city: String!) {
    office: allListings(
      filter: {
        spaceType: { eq: "Shared Workspace" }
        spaceCity: { eq: $city }
        monthPassAvailable: { eq: true }
      }
    ) {
      totalCount
      edges {
        node {
          monthPassAvailable
          dayPassAvailable
          hourPassAvailable
          officeSpaceType
          purposesList
          spaceAddress
          spaceGFName
          OriginalName
          spaceCity
          spaceId
          spaceImage
          spaceTitle
          spaceType
          subType
          priceDay
          priceHr
          priceMonth
          spaceDisplayName
          Facility
          slug
          hasCovidSafeBadge
        }
      }
    }
    coworking: allListings(
      filter: {
        spaceType: { eq: "Shared Workspace" }
        spaceCity: { eq: $city }
        dayPassAvailable: { eq: true }
      }
    ) {
      totalCount
      edges {
        node {
          monthPassAvailable
          dayPassAvailable
          hourPassAvailable
          officeSpaceType
          purposesList
          spaceAddress
          spaceGFName
          OriginalName
          spaceCity
          spaceId
          spaceImage
          spaceTitle
          spaceType
          subType
          priceDay
          priceHr
          priceMonth
          spaceDisplayName
          Facility
          slug
          hasCovidSafeBadge
        }
      }
    }
    meeting: allListings(
      filter: { spaceType: { eq: "Conference Room" }, spaceCity: { eq: $city } }
    ) {
      totalCount
      edges {
        node {
          monthPassAvailable
          dayPassAvailable
          hourPassAvailable
          officeSpaceType
          purposesList
          spaceAddress
          spaceGFName
          OriginalName
          spaceCity
          spaceId
          spaceImage
          spaceTitle
          spaceType
          subType
          priceDay
          priceHr
          priceMonth
          spaceDisplayName
          Facility
          slug
          hasCovidSafeBadge
        }
      }
    }
    allLocalities(
      filter: { cityName: { eq: $city } }
      sort: { fields: localityName }
    ) {
      edges {
        node {
          cityName
          cityPriority
          cityslug
          displayCity
          id
          localitySort
          localitySlug
          localityName
        }
      }
    }
    officeimage: file(relativePath: { eq: "coworking/office-space.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
